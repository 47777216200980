import React, { useEffect, useState } from "react";
import useEventListener from "../../../hooks/useEventListener";
import { className } from "../../../utils/helpers";
import * as styles from "./Header.module.scss";

const Isologo = ({ title }) => {
	const [reachedTop, setReachedTop] = useState();

	const checkScrollTop = () => {
		const { top } = document.body.getBoundingClientRect();
		setReachedTop(top === 0);
	};

	useEffect(checkScrollTop, []);
	useEventListener("scroll", checkScrollTop);

	return (
		<h3 {...className({ [styles.onTop]: reachedTop })}>
			{title.split(" ").map((word, i) => (
				<span key={i} className={styles.word}>
					{word.split("").map((letter, j) => (
						<span
							key={j}
							className={styles.letter}
							style={{ transitionDelay: `${j * 25}ms` }}
						>
							{letter}
						</span>
					))}
				</span>
			))}
		</h3>
	);
};

export default Isologo;
