import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { ovalShape } from "../../../utils/shapes";
import * as styles from "./CookiesPopup.module.scss";

const storageEntryName = "COOKIES_ACCEPTED";

const CookiesPopup = () => {
	const query = useStaticQuery(
		graphql`
			{
				allPrismicLegals {
					edges {
						node {
							data {
								popup_text {
									text
								}
								popup_accept_label {
									text
								}
								popup_decline_label {
									text
								}
							}
						}
					}
				}
			}
		`
	);
	const { data } = query.allPrismicLegals.edges[0].node;
	const [cookiesAccepted, setCookiesAcceptance] = useState(false);

	const setLocalStorage = (state) => {
		setCookiesAcceptance(true);
		localStorage.setItem(storageEntryName, state);
	};

	useEffect(() => {
		const lsEntry = localStorage.getItem(storageEntryName);
		if (lsEntry) setCookiesAcceptance(JSON.parse(lsEntry));
	}, []);

	return !cookiesAccepted ? (
		<div className={styles.cookiesPopup}>
			{ovalShape}

			<p>{data.popup_text.text}</p>

			<Link to="/legales#cookies">Más información</Link>

			<div className={styles.actions}>
				<button
					className={styles.decline}
					onClick={() => setLocalStorage(false)}
				>
					{data.popup_decline_label.text || "Declinar"}
				</button>

				<button
					className="base-cta"
					onClick={() => setLocalStorage(true)}
				>
					{data.popup_accept_label.text || "Aceptar"}
				</button>
			</div>
		</div>
	) : null;
};

export default CookiesPopup;
