// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-como-trabajo-jsx": () => import("./../../../src/pages/como-trabajo.jsx" /* webpackChunkName: "component---src-pages-como-trabajo-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-formacion-jsx": () => import("./../../../src/pages/formacion.jsx" /* webpackChunkName: "component---src-pages-formacion-jsx" */),
  "component---src-pages-historia-jsx": () => import("./../../../src/pages/historia.jsx" /* webpackChunkName: "component---src-pages-historia-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legales-jsx": () => import("./../../../src/pages/legales.jsx" /* webpackChunkName: "component---src-pages-legales-jsx" */)
}

