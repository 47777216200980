import React, { useEffect } from "react";
import { isBrowser } from "../../../utils/helpers";
import * as styles from "./Loader.module.scss";

const Loader = () => {
	const handleTransitionEnd = (e) => {
		e.currentTarget.style.display = "none";
	};

	useEffect(() => {
		if (!isBrowser() || window.PAGE_LOADING) return;
		window.PAGE_LOADING = true;
	});

	if (!isBrowser() || window.PAGE_LOADING || window.PAGE_LOADED) return null;

	return (
		!window.PAGE_LOADED && (
			<div
				id="loader"
				className={styles.loader}
				onTransitionEnd={handleTransitionEnd}
			>
				<div className={styles.spinner} />
			</div>
		)
	);
};

export default Loader;