import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { circleSketchShape } from "../../../utils/shapes";
import * as styles from "./NavLink.module.scss";

const NavLink = ({ link, closeNav }) => {
	const navLink = useRef(null);
	const [sketchLinkWidth, setSketchLineWidth] = useState(0);

	const getLinkWidth = (e) => {
		setSketchLineWidth(e.currentTarget.clientWidth);
	};

	useEffect(() => {
		if (!navLink?.current) return;
		setSketchLineWidth(navLink.current.clientWidth);
	}, []);

	return (
		<button
			ref={navLink}
			className={styles.navLink}
			onPointerOver={getLinkWidth}
			style={{ "--sketchCircleWidth": `${sketchLinkWidth}px` }}
		>
			<Link
				to={link.url}
				activeClassName={styles.active}
				onClick={closeNav}
				tabIndex={-1}
			>
				{link.label}
				{circleSketchShape}
			</Link>
		</button>
	);
};

export default NavLink;
