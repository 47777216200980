import React, { useState } from "react";
import { Link } from "gatsby";
import { className } from "../../../utils/helpers";
import NavLink from "../../shared/NavLink";
import Isologo from "./Isologo";
import * as styles from "./Header.module.scss";

const menuLinks = [
	{
		id: "home",
		url: "/",
		label: "Inicio",
	},
	{
		id: "history",
		url: "/historia",
		label: "Mi historia",
	},
	{
		id: "education",
		url: "/formacion",
		label: "Formación",
	},
	{
		id: "work",
		url: "/como-trabajo",
		label: "¿Cómo trabajo?",
	},
	{
		id: "contact",
		url: "/contacto",
		label: "Contacto",
	},
];

const Header = ({ siteTitle }) => {
	const [navVisible, setNavVisibility] = useState(false);

	return (
		<header className={styles.header}>
			<Link to="/" className={styles.siteTitle}>
				<Isologo title={siteTitle} />
			</Link>

			<button
				onClick={() => setNavVisibility(!navVisible)}
				{...className(styles.navToggler, {
					[styles.navVisible]: navVisible,
				})}
			>
				<span />
				<span />
				<span />
			</button>

			{navVisible && (
				<nav className={styles.nav}>
					{menuLinks.map((link) => (
						<NavLink
							key={link.id}
							closeNav={() => setNavVisibility(false)}
							{...{ link }}
						/>
					))}
				</nav>
			)}
		</header>
	);
};

export default Header;
