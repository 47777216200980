import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import * as styles from "./Footer.module.scss";

const Footer = () => {
	const query = useStaticQuery(
		graphql`
			{
				allPrismicFooter {
					edges {
						node {
							data {
								footer_title {
									text
								}
								footer_text {
									raw
								}
								footer_social_media {
									network_name {
										text
									}
									network_url {
										text
									}
									network_image {
										url
										alt
									}
									is_email
								}
								footer_legal_cta_text {
									text
								}
								footer_cookies_cta_text {
									text
								}
							}
						}
					}
				}
			}
		`
	);
	const { data } = query.allPrismicFooter.edges[0].node;

	return (
		<footer className={styles.footer}>
			<h4>{data.footer_title.text}</h4>

			{data.footer_text.raw.map((x, i) => (
				<p key={i}>{x.text}</p>
			))}

			<div className={styles.socialMedia}>
				{data.footer_social_media.map((x, i) => (
					<a
						key={i}
						target="_blank"
						rel="noreferrer noopener"
						href={
							x.is_email
								? `mailto:${x.network_url.text}?subject=Consulta&body=Hola...`
								: x.network_url.text
						}
						title={x.network_name.text}
					>
						<img
							src={x.network_image.url}
							alt={`${x.network_name.text} logo`}
						/>
					</a>
				))}
			</div>

			<div className={styles.legalLinks}>
				{data.footer_legal_cta_text.text && (
					<Link to="/legales">{data.footer_legal_cta_text.text}</Link>
				)}

				{data.footer_cookies_cta_text.text && (
					<Link to="/legales#cookies">
						{data.footer_cookies_cta_text.text}
					</Link>
				)}
			</div>
		</footer>
	);
};

export default Footer;
