import React from "react";

export const pictureShape = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348 287">
		<path d="M4.1 159.6C6.4 147.5 29.3 33.7 127.5 5.7c91.2-26 183 40.4 209.8 115.2 11.8 32.9 19.6 91.7-9 116.1-32.3 27.5-71.1-6.3-152.3 5.8C94 255 60.9 300.5 27.6 283c-32.8-17.1-30-87.6-23.5-123.4z" />
	</svg>
);

export const pictureShapeClip = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348 287">
		<clipPath id="picture-clip-path">
			<path d="M4.1496 159.594C6.40087 147.525 29.2737 33.7495 127.519 5.74309C218.74 -20.2365 310.502 46.0944 337.337 120.901C349.134 153.79 356.968 212.567 328.332 236.98C296.004 264.526 257.226 230.706 176 242.774C93.9639 255.027 60.8815 300.547 27.5628 283.043C-5.21567 265.908 -2.4241 195.431 4.1496 159.594Z" />
		</clipPath>
	</svg>
);

export const pictureShapeAlt = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 274.34 256.4">
		<path
			d="M129.07.83S18-3.76 1.92 108.07 110.69 255.9 153.58 255.9s120.26-14.56 120.26-122.56S168.13-3 129.07.83z"
			strokeMiterlimit="10"
		/>
	</svg>
);

export const pictureShapeAltClip = (
	<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0">
		<clipPath id="picture-clip-path" clipPathUnits="objectBoundingBox">
			<path d="M.5 0S.1 0 .1.4s.3.6.5.6.4-.1.4-.5S.6 0 .5 0z" />
		</clipPath>
	</svg>
);

export const circleShape = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="circle-shape"
		viewBox="0 0 173 173"
		fill="none"
	>
		<path d="M172.5 86.5c0 47.496-38.504 86-86 86s-86-38.504-86-86 38.504-86 86-86 86 38.504 86 86z" />
	</svg>
);

export const wavesShape = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="waves-shape"
		viewBox="0 0 245 424"
		fill="none"
	>
		<path d="M61.264 365.502C20.461 301.608 23 266.554 32.686 246.434c17.263-35.873 57.37-24.405 101.311-68.762 47.173-47.637 32.713-92.967 73.306-116.362 18.574-10.719 49.614-17.41 103.794 3.307" />
		<path d="M84.73 372.886c-39.441-61.893-37.042-95.838-27.661-115.32 16.682-34.693 55.549-23.616 98.121-66.621C200.839 144.821 186.847 101 226.187 78.26c17.947-10.392 48.073-16.848 100.47 3.204" />
		<path d="M115.064 382.431c-37.556-58.888-35.256-91.187-26.3-109.65 15.951-33.036 52.88-22.464 93.335-63.409 43.474-43.874 30.182-85.587 67.485-107.186 17.164-9.905 45.715-16.017 95.597 3.034" />
	</svg>
);

export const ovalShape = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="oval-shape"
		viewBox="0 0 210 210"
		fill="none"
	>
		<path
			d="M164.579 165.843c-41.126 23.761-93.727 9.684-117.488-31.442-11.91-20.612-19.337-47.712-17.077-70.682 1.13-11.479 4.676-21.897 11.259-29.966 6.573-8.056 16.203-13.807 29.583-15.918 10.553-.742 17.401.924 22.148 4.393 4.754 3.474 7.488 8.82 9.673 15.646 1.091 3.41 2.039 7.17 3.037 11.228l.346 1.408c.894 3.647 1.843 7.52 2.978 11.562 2.565 9.138 6.082 19.167 12.085 29.557 6.015 10.411 14.341 18.813 22.771 25.925 4.216 3.556 8.463 6.794 12.463 9.799l1.928 1.446c3.28 2.458 6.351 4.76 9.066 6.966 3.242 2.635 5.949 5.113 7.875 7.529 1.925 2.417 3.036 4.73 3.154 7.04.117 2.295-.742 4.672-2.934 7.234-2.199 2.57-5.719 5.301-10.867 8.275z"
			stroke="#99B6BA"
		/>

		<path
			d="M190.677 166.276c-41.365 23.899-94.272 9.74-118.171-31.625-23.9-41.365-16.311-111.813 29.293-119.313 45.605-7.5 21.706 34.675 45.605 76.04 23.899 41.365 84.638 50.999 43.273 74.898z"
			fill="#99B6BA"
		/>
	</svg>
);

export const quoteMarks = (
	<svg viewBox="0 0 51 40" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 39.64C8.64 39.64 5.856 38.488 3.648 36.184C1.536 33.784 0.48 30.616 0.48 26.68C0.48 17.848 3.36 10.936 9.12 5.944C11.616 3.64 14.496 1.912 17.76 0.759995L19.2 4.35999C17.184 5.03199 15.168 6.13599 13.152 7.67199C8.928 10.84 6.384 15.016 5.52 20.2L7.392 19.192C8.928 18.424 10.464 18.04 12 18.04C14.88 18.04 17.376 19.144 19.488 21.352C21.696 23.464 22.8 25.96 22.8 28.84C22.8 31.72 21.696 34.264 19.488 36.472C17.376 38.584 14.88 39.64 12 39.64ZM39.36 39.64C36 39.64 33.216 38.488 31.008 36.184C28.896 33.784 27.84 30.616 27.84 26.68C27.84 17.848 30.72 10.936 36.48 5.944C38.88 3.64 41.76 1.912 45.12 0.759995L46.56 4.35999C44.544 5.03199 42.528 6.13599 40.512 7.67199C36.288 10.84 33.744 15.016 32.88 20.2L34.752 19.192C36.288 18.424 37.824 18.04 39.36 18.04C42.24 18.04 44.736 19.144 46.848 21.352C49.056 23.464 50.16 25.96 50.16 28.84C50.16 31.72 49.056 34.264 46.848 36.472C44.736 38.584 42.24 39.64 39.36 39.64Z" />
	</svg>
);

export const circleSketchShape = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 115" fill="none">
		<path
			d="M40.5 25C166.603 52.4602 265.361 52.624 260.783 70.5643C252.817 101.866 13.3125 110.543 7.49996 83C4.55608 69.069 50.4351 46.9956 229.5 22.5"
			strokeMiterlimit="10"
		/>
	</svg>
);

export const arrowShape = (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 344 135" fill="none">
		<path d="M129.289 42.2907C168.378 34.0048 265.582 24.8342 341.683 54.4388M338.343 48.5625L340.334 51.6646L342.326 54.7668L335.54 56.8986M8.21139 97.3819C24.8967 130.162 64.9965 143.21 97.7769 126.524C130.557 109.839 143.605 69.7394 126.92 36.959C110.234 4.17867 70.1344 -8.86893 37.354 7.81637C4.57369 24.5017 -8.47391 64.6015 8.21139 97.3819Z" />
	</svg>
);

export const animatedWave = (
	width = "100%",
	height = "200px",
	fill = "#000",
	fillOpacity = 1,
	duration = 5
) => (
	<svg {...{ width, height }} fill="none">
		<path
			{...{ fill, fillOpacity }}
			d="M0 67 C 273,183 822,-40 1920.00,106 V 359 H 0 V 67 Z"
		>
			<animate
				{...{ fill, fillOpacity }}
				dur={`${duration}s`}
				repeatCount="indefinite"
				attributeName="d"
				values="M0 77 C 473,283  822,-40  1920,116  V 359 H 0 V 67 Z;  M0 77 C 473,-40  1222,283  1920,136  V 359 H 0 V 67 Z;  M0 77 C 973,260  1722,-53  1920,120  V 359 H 0 V 67 Z;  M0 77 C 473,283  822,-40  1920,116  V 359 H 0 V 67 Z"
			/>
		</path>
	</svg>
);
