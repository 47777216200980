import React from "react";
import useSiteMetadata from "../../hooks/useSiteMetadata";
import Loader from "../shared/Loader";
import Header from "./Header";
import Footer from "./Footer";
import CookiesPopup from "../shared/CookiesPopup";
import "../../styles/main.scss";

const Layout = ({ children }) => {
	const siteMetadata = useSiteMetadata();

	return (
		<>
			<Header siteTitle={siteMetadata?.title || `Title`} />
			<Loader />
			<main>{children}</main>
			<Footer />
			<CookiesPopup />
		</>
	);
};

export default Layout;
