const React = require("react");
const Layout = require("./src/components/Layout").default;

export const wrapPageElement = ({ element, props }) =>  <Layout {...props}>{element}</Layout>;

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => prevRouterProps !== routerProps;

export const onInitialClientRender = () => {
	const loadingDelay = 1000;

	const removeSpinner = () => {
		window.PAGE_LOADED = true;
		document.body.classList.add("loaded");
	};

	setTimeout(() => {
		if (document.readyState === "loading") {
			document.addEventListener("DOMContentLoaded", removeSpinner);
		} else {
			removeSpinner();
		}
	}, loadingDelay);
};